import React from "react"
import Layout from "../components/layout"
import SEO from "../utils/seo"

const Inscripcions = () => {
  return (
    <Layout title="Inscripcions">
      <SEO title="Inscripcions" />
      <br/>
      <br/>
      <div style={{ textAlign: 'center' }}>
        <iframe
          src="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdGCuS-i1v_bZptrjzFjFSCUsRlrFdDIBgms7y79yxigYsirw/viewform?embedded=true"
          width="800"
          title="inscripcions "
          height="800"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
        ></iframe>
      </div>
    </Layout>
  )
}

export default Inscripcions
